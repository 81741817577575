import qs from 'qs'
import { useCallback, useState } from 'react'

import { isAPIErrorResponse } from 'sdk/common/errors'
import { OAuth2Request } from 'sdk/entries/oauth2'
import { unwrapResult } from '@reduxjs/toolkit'

import useAppDispatch from 'hooks/useAppDispatch'
import { entriesOAuth } from 'thunks/entries/oauth'
import { parseQueryString } from 'util/queryString'

type QueryParams = {
  response_type: string
  client_id: string
  redirect_uri: string
  scope: string
  uid?: string
  unique_id?: string
  nonce?: string
  state?: string
}

const query = parseQueryString<QueryParams>()

type OAuthError = {
  error: string
  error_description: string
  statusCode: number
}

function isOAuthError(resp: any): resp is OAuthError {
  return 'error_description' in resp
}

export function useOAuth() {
  const [error, setError] = useState<string>()

  const dispatch = useAppDispatch()
  const authenticate = useCallback(
    async ({ uniqueId }: { uniqueId?: string }) => {
      try {
        const req: OAuth2Request = {
          responseType: query.response_type,
          clientId: query.client_id,
          redirectUri: query.redirect_uri,
          scope: query.scope,
          nonce: query.nonce,
          state: query.state,
          uniqueId,
        }

        return await dispatch(entriesOAuth(req)).then(unwrapResult)
      } catch (err) {
        if (isOAuthError(err)) {
          const { statusCode, ...rest } = err
          window.location.href = `${query.redirect_uri}?${qs.stringify(rest)}`
        } else if (isAPIErrorResponse(err)) {
          if (err.code === 'UNKNOWN_UNIQUE_ID') {
            setError('That Unique ID is invalid')
          } else {
            setError('An error occurred initiating the OAuth flow')
          }
        }
        throw err
      }
    },
    [setError, dispatch]
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [setError])

  return { authenticate, error, clearError, query }
}
