import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import r from 'routes'
import i18n from 'translation'

import { isAPIError } from 'sdk/common/errors'
import { BootstrapReply } from 'sdk/executions/bootstrap'
import { SubmitResponse } from 'sdk/executions/submit'

import useAppDispatch from 'hooks/useAppDispatch'
import useQueryString from 'hooks/useQueryString'
import useRedirect from 'hooks/useRedirect'
import { executionsBootstrap } from 'thunks/executions/bootstrap'

import { translateBootstrap } from '~p/pages/TransferredToMobile/hooks/useBootstrap'

type QueryParams = {
  token?: string
}

export default function useExecution() {
  const query = useQueryString<QueryParams>()
  const history = useHistory()

  /**
   * Ensure there's a pipeline token
   */
  useEffect(() => {
    if (query.token) return
    history.push(r.pipeline.error('no-bootstrap-token'))
  }, [query, history])

  /**
   * Store bootstrap in redux
   */
  const dispatch = useAppDispatch()
  const onBootstrap = useCallback(
    (resp: BootstrapReply) => {
      const bootstrap = translateBootstrap(resp)
      dispatch(executionsBootstrap(bootstrap))
      console.log('bootstrap', bootstrap)

      i18n.changeLanguage(bootstrap.language)
    },
    [dispatch]
  )

  /**
   * Pipeline was successfully completed
   */
  const redirect = useRedirect()
  const onSuccess = useCallback(
    (resp: SubmitResponse) => {
      if (resp.redirect) {
        redirect(resp.redirect)
      } else {
        history.push(r.pipeline.success)
      }
    },
    [redirect, history]
  )

  /**
   * There was an unrecoverable error
   */
  const onError = useCallback(
    (err: Error) => {
      console.log('[PIPELINE ERROR]', err)
      if (isAPIError(err) && err.errorCode) {
        history.push(r.pipeline.error(err.errorCode))
      } else {
        history.push(r.pipeline.error('general-error'))
      }
    },
    [history]
  )

  return { token: query.token, onBootstrap, onSuccess, onError }
}
