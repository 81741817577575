import { cardholderName, cvv, expirationDate, number, postalCode } from 'card-validator'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { t } from 'translation'

import Input from 'components/molecules/Form/Input'
import { ActionTimer } from 'hooks/useActionTimer'
import { ErrorResponse } from 'sdk/common/errors'
import { SubmitFn } from 'sdk/react/hooks/useStage'
import { CreditCardDetails } from 'sdk/types'
import insertSpaces from 'util/insertSpaces'

import CreditCardBrandIcon, { CCBrand } from '~p/components/atoms/CreditCardBrandIcon'
import Header from '~p/components/organisms/Header'
import FooterButton from '~pc/atoms/FooterButton'

import s from '../index.module.scss'

const formatCardNumber = (value: string) => {
  const card = number(value)
  if (!card.card) return value
  return insertSpaces(value, card.card.gaps)
}

type Props = {
  timer: ActionTimer
  error: ErrorResponse | undefined
  onComplete: SubmitFn<'payment-details'>
  onCancel: () => void
}

type State = Omit<CreditCardDetails, 'expirationMonth' | 'expirationYear'> & {
  expiration: string
}

const CreditCard: FC<Props> = ({ timer, error, onComplete, onCancel }) => {
  const [card, setCard] = useState<State>({
    nameOnCard: '',
    cardNumber: '',
    expiration: '',
    cvv: '',
    postalCode: '',
  })

  const cardInfo = useMemo(() => number(card.cardNumber).card, [card.cardNumber])

  const [errors, setErrors] = useState<Partial<State>>({})
  const checkErrors = useCallback((info: State) => {
    const errors: Partial<State> = {}

    const nc = cardholderName(info.nameOnCard)
    if (!nc.isValid) {
      errors.nameOnCard = 'Cardholder name is required'
    } else if (info.nameOnCard.split(' ').length < 2) {
      errors.nameOnCard = 'Cardholder first and last names are required'
    }

    const cn = number(info.cardNumber)
    if (!cn.isValid) {
      errors.cardNumber = 'Invalid card number'
    }

    const exp = expirationDate(info.expiration)
    if (!exp.isValid) {
      errors.expiration = 'Invalid expiration date'
    }

    const cv = cvv(info.cvv, cn.card?.code.size)
    if (!cv.isValid) {
      errors.cvv = 'Invalid CVV'
    }

    const pc = postalCode(info.postalCode)
    if (!pc.isValid) {
      errors.postalCode = 'Invalid postal code'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }, [])

  const setValue = useCallback(
    (key: keyof typeof card, value: string) => {
      if (Object.keys(errors).length > 0) setErrors({})
      setCard({ ...card, [key]: value })
    },
    [card, setCard, errors]
  )

  const onSubmit = useCallback(() => {
    if (!checkErrors(card)) {
      return
    }

    const exp = expirationDate(card.expiration)

    onComplete({
      creditCard: {
        nameOnCard: card.nameOnCard,
        cardNumber: card.cardNumber,
        expirationMonth: exp.month as string,
        expirationYear: exp.year as string,
        cvv: card.cvv,
        postalCode: card.postalCode,
      },
    })
  }, [card, checkErrors, onComplete])

  return (
    <section>
      <Header>
        <div className="flex items-center justify-between -mr-8">
          {t('Credit card details')}
          <span className="text-sm text-gray-400 cursor-pointer" onClick={onCancel}>
            cancel
          </span>
        </div>
      </Header>
      <form>
        <main className="px-4 sm:p-0">
          <div className="mt-4 space-y-4">
            <div>
              <label className={s.Label}>{t('Name on Card')}</label>
              <Input
                autoComplete="cc-name"
                autoFocus
                value={card.nameOnCard}
                onChange={v => setValue('nameOnCard', v.target.value)}
              />
              {errors.nameOnCard && <p className={s.Error}>{errors.nameOnCard}</p>}
            </div>

            <div>
              <label className={s.Label}>{t('Card Number')}</label>
              <div className="relative">
                {cardInfo && (
                  <CreditCardBrandIcon
                    brand={cardInfo.type as CCBrand}
                    className="absolute h-10 top-1 right-1"
                  />
                )}
                <Input
                  label={t('Card Number')}
                  autoComplete="cc-number"
                  inputMode="numeric"
                  maxLength={19}
                  value={formatCardNumber(card.cardNumber)}
                  onChange={v => setValue('cardNumber', v.target.value.replaceAll(/[^\d]/g, ''))}
                />
              </div>
              {errors.cardNumber && <p className={s.Error}>{errors.cardNumber}</p>}
            </div>

            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <label className={s.Label}>{t('Expiration')}</label>
                <Input
                  label="Expiration"
                  placeholder="MM/YY"
                  inputMode="numeric"
                  autoComplete="cc-exp"
                  maxLength={5}
                  value={card.expiration.replaceAll(/[^\d/]/g, '').replace(/^(\d{2})(\d)/, '$1/$2')}
                  onChange={v => setValue('expiration', v.target.value)}
                />
                {errors.expiration && <p className={s.Error}>{errors.expiration}</p>}
              </div>

              <div>
                <label className={s.Label}>{t('CVV')}</label>
                <Input
                  label="CVV"
                  autoComplete="cc-csc"
                  inputMode="numeric"
                  maxLength={cardInfo?.code.size || 4}
                  value={card.cvv}
                  onChange={v => setValue('cvv', v.target.value)}
                />
                {errors.cvv && <p className={s.Error}>{errors.cvv}</p>}
              </div>
            </div>

            <div>
              <label className={s.Label}>{t('Postal Code')}</label>
              <Input
                label="Postal Code"
                autoComplete="billing postal-code"
                inputMode="numeric"
                maxLength={10}
                value={card.postalCode}
                onChange={v => setValue('postalCode', v.target.value)}
              />
              {errors.postalCode && <p className={s.Error}>{errors.postalCode}</p>}
            </div>
          </div>
        </main>
        <footer className="px-4 mt-6 space-y-4 sm:px-0">
          {error && <p className={s.Error}>{error.error}</p>}
          <FooterButton timer={timer} disabled={timer.isLoading} onClick={onSubmit}>
            {t('Continue')}
          </FooterButton>
        </footer>
      </form>
    </section>
  )
}

export default CreditCard
